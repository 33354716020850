// Override Bootstrap variables
@import "variables";
// Import Bootstrap files
@import "../node_modules/bootstrap/scss/bootstrap-reboot";
@import "../node_modules/bootstrap/scss/bootstrap-grid";
@import "../node_modules/bootstrap/scss/utilities/spacing";
@import "../node_modules/bootstrap/scss/utilities/text";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";

a {
  &:focus, &:hover {
    text-decoration: none;
    opacity: .7;
    color: inherit;
    transition: opacity .2s ease-in-out;
  }
}

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

main {
  display: flex;
  align-items: center;
  padding: 3.5rem 0;
  flex: 1 0 auto;
}

.step-3 main {
  align-items: initial;
}

body > header {
  padding: 1.1rem 0;
  border-bottom: 1px solid whitesmoke;
}

body > footer {
  background-color: $gray-900;
  color: $gray-600;
  display: flex;
  align-items: center;
  font-size: .9rem;

  // Sticky
  height: $sticky-footer-height;
  flex-shrink: 0;

  a {
    color: $gray-600;
  }
}

.footer-content {
  text-align: center;
}

.footer-title {
  display: inline;
  font-weight: normal;
  font-size: 1em;
}

.header-student {
  margin-bottom: 0;
}

.header-company {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
}

.header-placement {
  margin-bottom: 0;
}

.sentences {
  padding-left: 1.9rem;
}

.sentence {
  padding-left: 1.25rem;
  margin-bottom: 2rem;
}

.sentence-text {
  margin-bottom: 0.8rem;
}

.choices {
  list-style: upper-alpha;
  padding-left: 1.16rem;
}

.choice {
  width: 100%;
  padding: 0 .5rem;

  @include media-breakpoint-up(md) {
    margin-right: 4.375rem;
    width: initial;
  }

  button {
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    transition: color .2s ease-in-out;

    &:hover, &:focus {
      color: $primary;
    }
  }
}

.input-word {
  background: transparent;
  border: 0;
  border-bottom: 1px solid;
  width: 5rem;
  text-align: center;
}

.badInput{
  border: 2px solid #f00 !important;
}

 .form-control .badInputSelect{
  border: 2px solid #f00 !important;
}


[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.btn-white{
  background-color : #fff !important;
  color : #aaa !important;
  border : 1px solid #ddd !important;
}





.t-1{top : -1px !important }
.t-2{top : -2px !important }
.t-3{top : -3px !important }
.t-4{top : -4px !important }
.t-5{top : -5px !important }
.t-6{top : -6px !important }
.t-7{top : -7px !important }
.t-8{top : -8px !important }
.t-9{top : -9px !important }
.t-10{top : -10px !important }

.m0{margin : 0px !important}
.m1{margin : 1px !important}
.m2{margin : 2px !important}
.m3{margin : 3px !important}
.m4{margin : 4px !important}
.m5{margin : 5px !important}
.m10{margin : 10px !important}
.m15{margin : 15px !important}
.m20{margin : 20px !important}
.m25{margin : 25px !important}
.m30{margin : 30px !important}
.m35{margin : 35px !important}
.m40{margin : 40px !important}
.m45{margin : 45px !important}
.m50{margin : 50px !important}
.m75{margin : 75px !important}
.m100{margin : 100px !important}

.mt-10{margin-top : -10px !important}
.mt-9{margin-top : -9px !important}
.mt-8{margin-top : -8px !important}
.mt-7{margin-top : -7px !important}
.mt-6{margin-top : -6px !important}
.mt-5{margin-top : -5px !important}
.mt-4{margin-top : -4px !important}
.mt-3{margin-top : -3px !important}
.mt-2{margin-top : -2px !important}
.mt-1{margin-top : -1px !important}
.mt0{margin-top : 0px !important}
.mt1{margin-top : 1px !important}
.mt2{margin-top : 2px !important}
.mt3{margin-top : 3px !important}
.mt4{margin-top : 4px !important}
.mt5{margin-top : 5px !important}
.mt6{margin-top : 6px !important}
.mt7{margin-top : 7px !important}
.mt8{margin-top : 8px !important}
.mt9{margin-top : 9px !important}
.mt10{margin-top : 10px !important}
.mt11{margin-top : 11px !important}
.mt12{margin-top : 12px !important}
.mt13{margin-top : 13px !important}
.mt14{margin-top : 14px !important}
.mt15{margin-top : 15px !important}
.mt20{margin-top : 20px !important}
.mt21{margin-top : 21px !important}
.mt22{margin-top : 22px !important}
.mt23{margin-top : 23px !important}
.mt24{margin-top : 24px !important}
.mt25{margin-top : 25px !important}
.mt30{margin-top : 30px !important}
.mt31{margin-top : 31px !important}
.mt32{margin-top : 32px !important}
.mt33{margin-top : 33px !important}
.mt34{margin-top : 34px !important}
.mt35{margin-top : 35px !important}
.mt36{margin-top : 36px !important}
.mt37{margin-top : 37px !important}
.mt38{margin-top : 38px !important}
.mt39{margin-top : 39px !important}
.mt40{margin-top : 40px !important}
.mt45{margin-top : 45px !important}
.mt50{margin-top : 50px !important}
.mt60{margin-top : 60px !important}
.mt75{margin-top : 75px !important}
.mt100{margin-top : 100px !important}
.mt120{margin-top : 120px !important}
.mt130{margin-top : 130px !important}
.mt140{margin-top : 140px !important}
.mt200{margin-top : 200px !important}
.mt300{margin-top : 300px !important}

.ml0{margin-left : 0px !important}
.ml1{margin-left : 1px !important}
.ml2{margin-left : 2px !important}
.ml3{margin-left : 3px !important}
.ml4{margin-left : 4px !important}
.ml5{margin-left : 5px !important}
.ml10{margin-left : 10px !important}
.ml11{margin-left : 11px !important}
.ml12{margin-left : 12px !important}
.ml13{margin-left : 13px !important}
.ml14{margin-left : 14px !important}
.ml15{margin-left : 15px !important}
.ml20{margin-left : 20px !important}
.ml25{margin-left : 25px !important}
.ml30{margin-left : 30px !important}
.ml35{margin-left : 35px !important}
.ml40{margin-left : 40px !important}
.ml45{margin-left : 45px !important}
.ml50{margin-left : 50px !important}
.ml60{margin-left : 60px !important}
.ml70{margin-left : 70px !important}
.ml75{margin-left : 75px !important}
.ml100{margin-left : 100px !important}

.mr0{margin-right : 0px !important}
.mr1{margin-right : 1px !important}
.mr2{margin-right : 2px !important}
.mr3{margin-right : 3px !important}
.mr4{margin-right : 4px !important}
.mr5{margin-right : 5px !important}
.mr6{margin-right : 6px !important}
.mr7{margin-right : 7px !important}
.mr8{margin-right : 8px !important}
.mr9{margin-right : 9px !important}
.mr10{margin-right : 10px !important}
.mr11{margin-right : 11px !important}
.mr12{margin-right : 12px !important}
.mr13{margin-right : 13px !important}
.mr14{margin-right : 14px !important}
.mr15{margin-right : 15px !important}
.mr20{margin-right : 20px !important}
.mr25{margin-right : 25px !important}
.mr30{margin-right : 30px !important}
.mr35{margin-right : 35px !important}
.mr40{margin-right : 40px !important}
.mr45{margin-right : 45px !important}
.mr50{margin-right : 50px !important}
.mr60{margin-right : 60px !important}
.mr75{margin-right : 75px !important}
.mr100{margin-right : 100px !important}

.mb0{margin-bottom : 0px !important}
.mb1{margin-bottom : 1px !important}
.mb2{margin-bottom : 2px !important}
.mb3{margin-bottom : 3px !important}
.mb4{margin-bottom : 4px !important}
.mb5{margin-bottom : 5px !important}
.mb10{margin-bottom : 10px !important}
.mb15{margin-bottom : 15px !important}
.mb16{margin-bottom : 16px !important}
.mb17{margin-bottom : 17px !important}
.mb18{margin-bottom : 18px !important}
.mb19{margin-bottom : 19px !important}
.mb20{margin-bottom : 20px !important}
.mb25{margin-bottom : 25px !important}
.mb30{margin-bottom : 30px !important}
.mb35{margin-bottom : 35px !important}
.mb40{margin-bottom : 40px !important}
.mb45{margin-bottom : 45px !important}
.mb50{margin-bottom : 50px !important}
.mb75{margin-bottom : 75px !important}
.mb100{margin-bottom : 100px !important}
.mb120{margin-bottom : 120px !important}
.mb130{margin-bottom : 130px !important}
.mb140{margin-bottom : 140px !important}
.mb200{margin-bottom : 200px !important}

.p0{padding : 0px !important}
.p1{padding : 1px !important}
.p2{padding : 2px !important}
.p3{padding : 3px !important}
.p4{padding : 4px !important}
.p5{padding : 5px !important}
.p10{padding : 10px !important}
.p11{padding : 11px !important}
.p12{padding : 12px !important}
.p13{padding : 13px !important}
.p14{padding : 14px !important}
.p15{padding : 15px !important}
.p20{padding : 20px !important}
.p25{padding : 25px !important}
.p30{padding : 30px !important}
.p35{padding : 35px !important}
.p40{padding : 40px !important}
.p45{padding : 45px !important}
.p50{padding : 50px !important}
.p75{padding : 75px !important}
.p100{padding : 100px !important}

.pt0{padding-top : 0px !important}
.pt1{padding-top : 1px !important}
.pt2{padding-top : 2px !important}
.pt3{padding-top : 3px !important}
.pt4{padding-top : 4px !important}
.pt5{padding-top : 5px !important}
.pt6{padding-top : 6px !important}
.pt7{padding-top : 7px !important}
.pt8{padding-top : 8px !important}
.pt9{padding-top : 9px !important}
.pt10{padding-top : 10px !important}
.pt11{padding-top : 11px !important}
.pt12{padding-top : 12px !important}
.pt13{padding-top : 13px !important}
.pt14{padding-top : 14px !important}
.pt15{padding-top : 15px !important}
.pt20{padding-top : 20px !important}
.pt21{padding-top : 21px !important}
.pt22{padding-top : 22px !important}
.pt23{padding-top : 23px !important}
.pt24{padding-top : 24px !important}
.pt25{padding-top : 25px !important}
.pt30{padding-top : 30px !important}
.pt35{padding-top : 35px !important}
.pt40{padding-top : 40px !important}
.pt45{padding-top : 45px !important}
.pt50{padding-top : 50px !important}
.pt55{padding-top : 55px !important}
.pt60{padding-top : 60px !important}
.pt61{padding-top : 61px !important}
.pt62{padding-top : 62px !important}
.pt70{padding-top : 70px !important}
.pt75{padding-top : 75px !important}
.pt80{padding-top : 80px !important}
.pt90{padding-top : 90px !important}
.pt100{padding-top : 100px !important}
.pt110{padding-top : 110px !important}
.pt120{padding-top : 120px !important}
.pt130{padding-top : 130px !important}
.pt135{padding-top : 135px !important}
.pt140{padding-top : 140px !important}
.pt150{padding-top : 150px !important}
.pt200{padding-top : 200px !important}


.pl0{padding-left : 0px !important}
.pl1{padding-left : 1px !important}
.pl2{padding-left : 2px !important}
.pl3{padding-left : 3px !important}
.pl4{padding-left : 4px !important}
.pl5{padding-left : 5px !important}
.pl6{padding-left : 6px !important}
.pl7{padding-left : 7px !important}
.pl8{padding-left : 8px !important}
.pl9{padding-left : 9px !important}
.pl10{padding-left : 10px !important}
.pl15{padding-left : 15px !important}
.pl20{padding-left : 20px !important}
.pl25{padding-left : 25px !important}
.pl30{padding-left : 30px !important}
.pl35{padding-left : 35px !important}
.pl40{padding-left : 40px !important}
.pl45{padding-left : 45px !important}
.pl50{padding-left : 50px !important}
.pl60{padding-left : 60px !important}
.pl65{padding-left : 65px !important}
.pl70{padding-left : 70px !important}
.pl75{padding-left : 75px !important}
.pl100{padding-left : 100px !important}
.pl200{padding-left : 200px !important}
.pl300{padding-left : 300px !important}
.pl400{padding-left : 400px !important}
.pl500{padding-left : 500px !important}

.pr0{padding-right : 0px !important}
.pr1{padding-right : 1px !important}
.pr2{padding-right : 2px !important}
.pr3{padding-right : 3px !important}
.pr4{padding-right : 4px !important}
.pr5{padding-right : 5px !important}
.pr6{padding-right : 6px !important}
.pr7{padding-right : 7px !important}
.pr8{padding-right : 8px !important}
.pr9{padding-right : 9px !important}
.pr10{padding-right : 10px !important}
.pr15{padding-right : 15px !important}
.pr20{padding-right : 20px !important}
.pr25{padding-right : 25px !important}
.pr30{padding-right : 30px !important}
.pr35{padding-right : 35px !important}
.pr40{padding-right : 40px !important}
.pr45{padding-right : 45px !important}
.pr50{padding-right : 50px !important}
.pr75{padding-right : 75px !important}
.pr100{padding-right : 100px !important}
.pr200{padding-right : 200px !important}
.pr300{padding-right : 300px !important}
.pr400{padding-right : 400px !important}
.pr500{padding-right : 500px !important}

.pb0{padding-bottom : 0px !important}
.pb1{padding-bottom : 1px !important}
.pb2{padding-bottom : 2px !important}
.pb3{padding-bottom : 3px !important}
.pb4{padding-bottom : 4px !important}
.pb5{padding-bottom : 5px !important}
.pb6{padding-bottom : 6px !important}
.pb7{padding-bottom : 7px !important}
.pb8{padding-bottom : 8px !important}
.pb9{padding-bottom : 9px !important}
.pb10{padding-bottom : 10px !important}
.pb11{padding-bottom : 11px !important}
.pb12{padding-bottom : 12px !important}
.pb13{padding-bottom : 13px !important}
.pb14{padding-bottom : 14px !important}
.pb15{padding-bottom : 15px !important}
.pb20{padding-bottom : 20px !important}
.pb21{padding-bottom : 21px !important}
.pb22{padding-bottom : 22px !important}
.pb23{padding-bottom : 23px !important}
.pb24{padding-bottom : 24px !important}
.pb25{padding-bottom : 25px !important}
.pb30{padding-bottom : 30px !important}
.pb35{padding-bottom : 35px !important}
.pb40{padding-bottom : 40px !important}
.pb45{padding-bottom : 45px !important}
.pb50{padding-bottom : 50px !important}
.pb60{padding-bottom : 60px !important}
.pb61{padding-bottom : 61px !important}
.pb62{padding-bottom : 62px !important}
.pb75{padding-bottom : 75px !important}
.pb100{padding-bottom : 100px !important}

.b{font-weight: bold !important;}

.l{text-align: left !important;;}
.c{text-align: center !important;;}
.r{text-align: right !important;;}

.floatLeft{float: left !important;;}
.floatRight{float: right !important;;}

.wfull{ width: 100% !important; }
.whalf{ width: 50% !important; }
.w10{ width: 10px !important; }
.w20{ width: 20px !important; }
.w30{ width: 30px !important; }
.w31{ width: 31px !important; }
.w32{ width: 32px !important; }
.w33{ width: 33px !important; }
.w34{ width: 34px !important; }
.w35{ width: 35px !important; }
.w40{ width: 40px !important; }
.w50{ width: 50px !important; }
.w60{ width: 60px !important; }
.w70{ width: 70px !important; }
.w80{ width: 80px !important; }
.w81{ width: 81px !important; }
.w82{ width: 82px !important; }
.w83{ width: 83px !important; }
.w84{ width: 84px !important; }
.w85{ width: 85px !important; }
.w86{ width: 86px !important; }
.w87{ width: 87px !important; }
.w88{ width: 88px !important; }
.w89{ width: 89px !important; }
.w90{ width: 90px !important; }
.w100{ width: 100px !important; }
.w110{ width: 110px !important; }
.w111{ width: 111px !important; }
.w112{ width: 112px !important; }
.w113{ width: 113px !important; }
.w114{ width: 114px !important; }
.w115{ width: 115px !important; }
.w116{ width: 116px !important; }
.w117{ width: 117px !important; }
.w118{ width: 118px !important; }
.w119{ width: 119px !important; }
.w120{ width: 120px !important; }
.w130{ width: 130px !important; }
.w140{ width: 140px !important; }
.w150{ width: 150px !important; }
.w160{ width: 160px !important; }
.w170{ width: 170px !important; }
.w180{ width: 180px !important; }
.w190{ width: 190px !important; }
.w200{ width: 200px !important; }
.w210{ width: 210px !important; }
.w220{ width: 220px !important; }
.w230{ width: 230px !important; }
.w240{ width: 240px !important; }
.w250{ width: 250px !important; }
.w260{ width: 260px !important; }
.w270{ width: 270px !important; }
.w280{ width: 280px !important; }
.w290{ width: 290px !important; }
.w300{ width: 300px !important; }
.w350{ width: 350px !important; }
.w400{ width: 400px !important; }
.w450{ width: 450px !important; }
.w500{ width: 500px !important; }
.w550{ width: 550px !important; }
.w600{ width: 600px !important; }

.h10{ height: 10px !important; }
.h20{ height: 20px !important; }
.h30{ height: 30px !important; }
.h40{ height: 40px !important; }
.h50{ height: 50px !important; }
.h60{ height: 60px !important; }
.h70{ height: 70px !important; }
.h80{ height: 80px !important; }
.h90{ height: 90px !important; }
.h100{ height: 100px !important; }
.h110{ height: 110px !important; }
.h120{ height: 120px !important; }
.h130{ height: 130px !important; }
.h140{ height: 140px !important; }
.h150{ height: 150px !important; }
.h160{ height: 160px !important; }
.h170{ height: 170px !important; }
.h180{ height: 180px !important; }
.h190{ height: 190px !important; }
.h200{ height: 200px !important; }
.h210{ height: 210px !important; }
.h220{ height: 220px !important; }
.h230{ height: 230px !important; }
.h240{ height: 240px !important; }
.h250{ height: 250px !important; }
.h260{ height: 260px !important; }
.h300{ height: 300px !important; }
.h350{ height: 350px !important; }
.h400{ height: 400px !important; }
.h450{ height: 450px !important; }
.h500{ height: 500px !important; }
.h550{ height: 550px !important; }



.grigio3{ color:#333 !important; }
.grigio6{ color:#666 !important; }
.grigio9{ color:#999 !important; }
.grigioA{ color:#AAA !important; }
.grigioC{ color:#CCC !important; }
.grigioD{ color:#DDD !important; }
.grigioE{ color:#EEE !important; }
.grigioF0{ color:#F0F0F0 !important; }
.grigioFA{ color:#FAFAFA !important; }
.grigioFE{ color:#FEFEFE !important; }
.nero{ color:#000 !important; }
.bianco{ color:#FFF !important; }
.arancio { color:#FF6F00 !important;}

.bg-grigio3{ background-color:#333 !important; }
.bg-grigio4{ background-color:#444 !important; }
.bg-grigio5{ background-color:#555 !important; }
.bg-grigio6{ background-color:#666 !important; }
.bg-grigio9{ background-color:#999 !important; }
.bg-grigioA{ background-color:#AAA !important; }
.bg-grigioC{ background-color:#CCC !important; }
.bg-grigioD{ background-color:#DDD !important; }
.bg-grigioE{ background-color:#EEE !important; }
.bg-grigioF0{ background-color:#F0F0F0 !important; }
.bg-grigioF6{ background-color:#F6F6F6 !important; }
.bg-grigioF9{ background-color:#F9F9F9 !important; }
.bg-grigioFA{ background-color:#FAFAFA !important; }
.bg-grigioFE{ background-color:#FEFEFE !important; }
.bg-bianco{ background-color:#FFF !important; }
.bg-azzurro{ background-color:#e5f0f6 !important; }
.bg-azzurroChiaro{ background-color:#F5F9FC !important; }

.bg-scuro{ background-color:rgba(0,0,0,0.1) !important; }
.bg-azzurrino{ background-color: #0502010C !important; }

.bg-searchOff{ background-color:#0502010c; }

.bg-successLight{ background-color: #e6fff4 !important; }
.bg-warningLight{ background-color: #fff1e6 !important; }
.bg-dangerLight{ background-color: #feeae7 !important; }
.bg-sfondoAzzurrino { background-color : #f0f9ff; }
.bg-dangerStrong {background-color : #f2d9de !important; }
.bg-warningStrong {background-color : #f8f2d0 !important; }
.bg-successStrong {background-color : #d4edca !important; }
