$sticky-footer-height: 60px;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px
);

$red: #dd3333;
$primary: $red;
$danger: #ef1313;

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;